<template>
  <div class="login">
    <logo />
    <h1 class="mb-8 text-xl leading-8.5">
      {{ $t('login-view.title') }}
    </h1>
    <well
      class="w-full sm:w-136"
      :class="{ 'azure-wrapper': azureAuthEnabled }"
    >
      <ValidationObserver v-if="!azureAuthEnabled" v-slot="{ handleSubmit }">
        <form class="form mb-4" @submit.prevent="handleSubmit(send)">
          <p v-if="apiError" class="error-msg">
            {{ $t('login-view.login-error') }}
          </p>
          <ValidationProvider v-slot="{ errors }" rules="required|email">
            <text-input
              v-model="login"
              :placeholder="$t('login-view.email')"
              :error="apiError"
              :error-message="errors[0]"
              name="email"
              type="email"
              class="w-full mb-6"
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <text-input
              v-model="password"
              :placeholder="$t('login-view.password')"
              :error="apiError"
              :error-message="errors[0]"
              type="password"
              name="password"
              class="w-full mb-6"
            />
          </ValidationProvider>
          <btn
            :disabled="loading"
            :is-loading="loading"
            type="submit"
            class="w-full"
          >
            {{ $t('login-view.login') }}
          </btn>
        </form>
      </ValidationObserver>
      <div v-if="azureAuthEnabled">
        <btn theme="secondary" class="w-full" @click="authenticateWithAzure">
          {{ $t('login-view.microsoft-account') }}
        </btn>
      </div>
      <router-link
        v-if="!azureAuthEnabled"
        :to="{ name: 'ForgotPassword' }"
        class="text-primary absolute bottom-8 right-1/2 transform translate-x-1/2 text-sm"
      >
        {{ $t('login-view.forgot-password') }}
      </router-link>
    </well>
    <azure-authentication v-if="azureAuthEnabled && azureAuthInProgress" />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapActions, mapGetters } from 'vuex';
import { TextInput } from '@/components/Inputs';
import Btn from '@/components/Button/Button.vue';
import Well from '@/components/Well/Well.vue';
import AzureAuthentication from '@/components/AzureAuthentication/AzureAuthentication';
import Logo from '@/components/Logo/Logo';
import { emailRegex } from '@/util/form';
import { useCheckIfSurveysWereClosed } from '@/composables/use-check-if-surveys-were-closed';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TextInput,
    Btn,
    Well,
    AzureAuthentication,
    Logo,
  },
  setup() {
    const { setSurveysClose } = useCheckIfSurveysWereClosed();
    const resetSurveyClosed = () => setSurveysClose(false);
    return { resetSurveyClosed };
  },
  data() {
    return {
      login: '',
      password: '',
      apiError: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('azure', ['azureAuthEnabled', 'azureAuthInProgress']),
  },
  created() {
    extend('email', {
      validate(value) {
        return value.match(emailRegex);
      },
      message: this.$t('login-view.login-error-email'),
    });

    extend('required', {
      ...required,
      message: this.$t('login-view.login-error-empty'),
    });
  },
  methods: {
    ...mapActions('user', ['loginUser']),
    ...mapActions('azure', ['authenticateWithAzure']),
    async send() {
      this.loading = true;
      try {
        await this.loginUser({
          email: this.login,
          password: this.password,
        });
        this.resetSurveyClosed();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.apiError = true;
      }
    },
  },
};
</script>
<style scoped>
.login {
  @apply p-4 flex flex-col items-center justify-center h-screen text-white bg-cover;
  background-image: url('../assets/background.png');
}
.form {
  @apply w-full relative;
}

.error-msg {
  @apply text-center text-red absolute -mt-9 top-0 w-full;
}

.azure-wrapper {
  @apply py-8;
}
</style>
